<template >
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
      <el-breadcrumb-item>金融超市</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="concent clearfix">
      <section class="section3">
        <div class="chartView clearfix">
          <div class="top clearfix">
            <div class="title fl">银行机构</div>
            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt="" class="img fr toMore" />
          </div>
          <div class="center">
            <div class="bank-icon fl fl-c-c" style="width: 25%" v-for="(item, index) in banks" :key="index" @click="go(item)">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="section3">
        <div class="finance-info">
          <div class="header">申请记录</div>
          <div class="info-content">
            <div class="enterprise-item" v-for="(item, index) in list" :key="index">
              <div class="name">
                银行机构名称：<span>{{ item.bankName }}</span>
              </div>
              <div class="item-info">
                <div style="flex: 2">
                  客户名称名称：<span class="text">{{ item.custName }}</span>
                </div>
                <div class="fl-1">
                  联系人名称：<span class="text">{{ item.contactName }}</span>
                </div>
                <div class="fl-1">
                  担保方式：<span class="text">{{ item.guaranteeType }}</span>
                </div>
                <div class="fl-1">
                  申贷形式：<span class="text">{{ item.financingType }}</span>
                </div>
                <div class="fl-1">
                  申贷金额：<span class="text">{{ item.amount }}万元</span>
                </div>
                <div class="fl-1">
                  申请时间：<span class="blue">{{ item.applyTime }}</span>
                </div>
                <div class="fl-1">
                  处理状态：<span class="blue">{{ item.flag }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
//
export default {
  name: 'demandDetails',
  data() {
    return {
      banks: [
        { src: require('@/assets/img/bank/lwnsyh.jpg'), url: 'https://app.lwrcb.com/financialMarket/index.html' },
        { src: require('@/assets/img/bank/zgjsyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zggsyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zgyzyh.jpg'), url: '' },
        { src: require('@/assets/img/bank/zgyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zsyh.jpg'), url: '' },
        { src: require('@/assets/img/bank/hzyh.jpeg'), url: '' },
      ],
      list: [
        {
          amount: 2000000.0,
          address: '瓯江口',
          flag: '放款成功',
          purpose: '生产经营',
          endDate: '2024-05-04',
          contactName: '张三',
          bankName: '龙湾农商银行',
          custName: '浙江铂莱曼动力科技有限公司',
          financingType: '融资',
          bankId: '1',
          phone: '13888888888',
          guaranteeType: '抵押',
          id: 2,
          applyTime: '2021-05-04',
          socialCode: '91330301MA2AWT8L6L',
          startDate: '2021-05-04',
        },
        {
          amount: 228.0,
          address: '测试',
          flag: '审核中',
          maturity: '22',
          purpose: '办公室',
          contactName: '测试',
          bankName: '龙湾农商银行',
          custName: '查',
          financingType: '贷款',
          bankId: '18',
          phone: '1563567345',
          guaranteeType: '测试',
          id: 1,
          applyTime: '2021-05-03',
          socialCode: '91330301MA2AWT8L6L',
        },
      ],
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    go(e) {
      if (e.url) {
        let name = this.$route.query.name
        let code = this.$route.query.code
        // this.$alert(`根据您的综合评估情况，当前你可贷款额度约为100万元，具体以银行审核通过数据为准。`, '', {
        //   confirmButtonText: '确定',
        //   dangerouslyUseHTMLString: true,
        //   center: true,
        // }).then((result) => {
        //   });
          this.$router.push({ name: 'iframeUrl', params: { iframeUrl: `${e.url}?companyName=${name}&code=${code}` } });
      } else {
        this.$alert(`<img src="${e.src}" alt="" style='width:100px'/><br>正在洽谈中，敬请期待`, '', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          center: true,
        });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.w20 {
  width: 20%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.el-button--default {
  background: #135694;
  color: #fff;
}

.wrapper {
  padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 15px;
  margin: 0 auto;
  min-width: 1366px;

  section {
    margin-bottom: 16px;
  }

  .concent {
    height: 100%;
  }
}
.toMore {
  cursor: pointer;
}

.chartView {
  padding: 16px 20px 24px;
  background-color: #fff;
}

.chartView .top {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 16px;
}

.chartView .item-box {
  width: calc((100% - 40px) * 0.33333);
  height: 176px;
  border-radius: 0px 0px 4px 4px;
  background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 20px;
}

.chartView .item-box:nth-child(3n + 1) {
  margin-right: 0;
}

.chartView .item-box:nth-child(n + 4) {
  margin-bottom: 0;
}

.chartView .item-box .item-top {
  border-top: 2px solid #4393f8;
  margin-bottom: 16px;
  position: relative;
}

.chartView .item-box .item-top .top-bg {
  height: 0px;
  width: 172px;
  border-top: 40px solid #4393f8;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -96px;
}

.chartView .center p {
  line-height: 30px;
}

.chartView .item-box .item-bottom {
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  margin-top: 22px;
  background: #cee4ff;
  border-radius: 0px 0px 4px 4px;
}

.chartView .top .time {
  font-size: 14px;
  color: #666666;
  text-align: right;
  line-height: 20px;
  font-weight: 600;
}

.chartView .top .img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
}

.section3 .chartView .top .img,
.section4 .chartView .top .img {
  margin-top: 10px;
}

.chartView .item-box .item-title {
  height: 40px;
  line-height: 40px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  z-index: 1;
  position: relative;
}

.concent .title {
  font-family: 'MicrosoftYaHei-Bold';
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
  position: relative;
  padding-left: 14px;
}

.concent .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4393f8;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: 0;
}

.section3 .center .pd-item {
  width: calc((100% - 22px) * 0.5);
  height: 135px;
  background: #fafafa;
  padding: 20px;
}

.section3 .center .pd-item {
  margin-right: 22px;
  margin-bottom: 22px;
}

.section3 .center .pd-item:nth-child(2n) {
  margin-right: 0;
}

.section3 .center .pd-item:nth-child(n + 3) {
  margin-bottom: 0;
}

.section3 .center .pd-item .pic {
  width: 119px;
  height: 119px;
  margin-right: 25px;
  background-color: #4393f8;
}

.section3 .center .pd-item .pic img {
  width: 100%;
  height: 100%;
}

.section3 .center .pd-item .tl {
  margin: 12px 0;
}

.section3 .center .pd-item .tl-bg {
  display: inline-block;
  padding: 3px 13px;
  color: #fff;
  margin-right: 11px;
  position: relative;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  z-index: 0;
}

.section3 .center .pd-item .tl-bg:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #5583cc;
  transform: skew(-10deg);
  border-radius: 4px;
}

.section3 .center .pd-item .xy:before {
  background: #ffa75c;
}

.section3 .center .pd-item .text {
  width: calc(100% - 144px);
}

.section3 .center .pd-item .text .num {
  font-size: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 28px;
}

.section3 .center .pd-item .text .dw {
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
}

.chartView {
  height: 100%;
}

.chartView .bank-icon {
  text-align: center;
  height: 150px;
  margin-bottom: 10px;
}
.chartView .bank-icon img {
  width: 150px;
  cursor: pointer;
}
.finance-info {
  margin: 16px 0;
  background-color: #fff;
}

.header {
  font-size: 26px;
  color: #000000;
  line-height: 74px;
  text-indent: 14px;
  border-bottom: 1px solid #dddddd;
  position: relative;
  margin: 0 20px;
}
.header::before {
  content: '';
  background: #4393f8;
  border-radius: 3px;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 27px;
  left: 0;
}
.info-content {
  padding: 16px 20px;
  padding-top: 0;
}

.btn {
  height: 40px;
  line-height: 40px;
}

.btn-sub {
  color: #666;
  margin-left: 12px;
}

.enterprise-item {
  background: #fafafa;
  padding: 0 16px;
  margin-top: 16px;
}

.enterprise-item .name {
  font-size: 14px;
  color: #0e459c;
  line-height: 40px;
  border-bottom: 1px dashed #dddddd;
  cursor: pointer;
}

.enterprise-item .name span {
  font-weight: 600;
}

.enterprise-item .item-info {
  display: flex;
  font-size: 14px;
  color: #999999;
  line-height: 50px;
}

.enterprise-item .item-info .text {
  color: #151515;
}

.blue {
  color: #5babfe;
}
</style>